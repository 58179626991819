/*
 * @Description:
 * @Author: 周强
 * @Date: 2022-01-04 14:24:20
 * @LastEditTime: 2022-01-05 09:52:19
 * @LastEditors:
 */
import request from '@/utils/request'

const payapi = {
  verCode: '/insurance/giftVoucher/verCode',
  exchange: '/insurance/giftVoucher/exchange',
  setShipAddress: '/insurance/giftVoucher/setShipAddress',
  getCityCascader: '/basics/city/getCityCascader',
  giftList: '/insurance/giftVoucher/giftList', // //请求的url地址
  export: '/insurance/giftVoucher/export' // 导出
}

/**
 * 获取验证码
 * @param {*} parameter
 */
export function getCode(parameter) {
  return request.post(payapi.verCode, parameter)
}
/**
 * 兑换礼品
 * @param {*} parameter
 */
export function exchange(parameter) {
  return request.post(payapi.exchange, parameter)
}
/**
 * 设置收货地址
 * @param {*} parameter
 */
export function setShipAddress(parameter) {
  return request.post(payapi.setShipAddress, parameter)
}
/**
 * 获取省市区
 * @param {*} parameter
 */
export function getCityCascader(parameter) {
  return request.post(payapi.getCityCascader, parameter)
}
/**
 * 过节兑换礼品清单
 * @param {*} parameter
 */
export function getGiftList(parameter) {
  return request.get(payapi.giftList, parameter)
}

/**
 *
 * @param {type: 8} parameter
 * @returns
 */
export function gitRecordExport(parameter) {
  return request.get(payapi.export, parameter)
}
