<template>
  <div class="queryEntry">
    <div class="logo">
      <img
        :src="
          require(`@/assets/official-account/${
            type !== 'picc' ? 'logo.png' : 'logo.png'
          }`)
        "
        alt=""
      />
    </div>
    <div class="txt">
      <h2>{{ isOrder == 1 ? "订单查询" : "保单查询" }}</h2>
    </div>
    <van-form @submit="onSubmit" class="form">
      <div class="input_box">
        <div class="inp">
          <van-field
            v-model="certificate"
            name="certificate"
            label="保单凭证"
            maxlength="30"
            placeholder="请输入保单凭证/身份证/车架号"
            input-align="left"
            :rules="[{ required: true }]"
          />
        </div>
      </div>
      <div class="input_box">
        <div class="inp">
          <van-field
            v-model="contactPhone"
            name="contactPhone"
            label="手机号码"
            maxlength="30"
            placeholder="请输入手机号码"
            input-align="left"
            :rules="[{ required: true }]"
          />
        </div>
      </div>
      <div class="input_box">
        <div class="inp">
          <van-field
            :center="true"
            v-model="verCode"
            name="verCode"
            placeholder="请输入验证码"
            label="验证码"
            input-align="left"
            :rules="[{ required: true }]"
          />
          <img :src="codeSrc" class="codeimg" @click="getVerifyCode" />
        </div>
      </div>
      <div class="div_btn">
        <van-button class="query" round block type="info" native-type="submit"
          >确认查询</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { getCode } from "@/api/springFestival";
import { verify } from "@/api/verification";
export default {
  name: "SearchService",
  data() {
    return {
      codeSrc: "", // 图形验证码
      verCode: "",
      certificate: "",
      contactPhone: "",
      uuid: "",
      type: "",
      isClaim: "",
      isOrder: "",
    };
  },
  mounted() {
    this.getVerifyCode();
    this.isClaim = this.$route.query.isClaim || "";
    this.isOrder = this.$route.query.isOrder || "";
    if (this.isOrder == 1) {
      document.title = "订单查询";
    }
  },
  methods: {
    // 获取验证码
    getVerifyCode() {
      getCode().then((res) => {
        this.codeSrc = res.content.code;
        this.uuid = res.content.uuid;
        this.verCode = "";
      });
    },
    // 查询
    async onSubmit() {
      const params = {
        keyword: this.certificate,
        contactPhone: this.contactPhone,
        verCode: this.verCode,
        uuid: this.uuid,
      };
      const resp = await verify(params);
      if (!resp.success) {
        this.$toast.fail(resp.respMag);
        this.getVerifyCode();
        return;
      }
      if (resp.content) {
        // this.type = resp.content.insuranceOrg
        const insuranceList = resp.content.insuranceList;
        if (insuranceList && insuranceList.length == 1) {
          if (this.isClaim == 1) {
            this.$router.push({
              path: "/verification/claimApply",
              query: { keyword: this.certificate },
            });
          } else if (this.isOrder == 1) {
            this.$router.push({
              path: "/verification/orderDetails",
              query: { keyword: this.certificate },
            });
          } else {
            this.$router.push({
              path: "/verification/detail",
              query: { keyword: this.certificate, index: 0 },
            });
          }
        } else {
          this.$router.push({
            path: "/verification/product",
            query: {
              keyword: this.certificate,
              isClaim: this.isClaim,
              isOrder: this.isOrder,
            },
          });
        }
      }
    },

    // goto(index) {
    // }
  },
};
</script>
<style lang="postcss" scoped>
.queryEntry {
  font-size: 12px;
}

.queryEntry .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.queryEntry .logo img {
  width: 100%;
}

.queryEntry .txt {
  border-bottom: 2px solid #ed5d43;
}

.queryEntry .txt h2 {
  background: #ed5d43;
  color: #fff;
  font-weight: normal;
  text-align: center;
  display: inline-block;
  padding: 8px 20px;
  font-size: 14px;
  margin: 0;
}

.queryEntry .input_box {
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 10px;
  align-items: center;
}

.queryEntry .input_box .name {
  width: 80px;
  line-height: 30px;
  text-align: center;
}

.queryEntry .input_box .inp {
  display: flex;
  align-items: center;
  width: 100%;
}

.queryEntry .input_box .inp img {
  margin-left: 10px;
  height: 30px;
}

.queryEntry .input_box input {
  width: 100%;
  border: 0;
  outline: none;
  line-height: 30px;
}

.queryEntry .div_btn {
  padding: 20px 10px;
}

.queryEntry .div_btn .query {
  background: #ed5d43;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  border: 0;
}

>>> .van-cell {
  align-items: center;
}

>>> .van-cell::after {
  border: none;
}
</style>
